<template>
    <div :class="isReport ? 'divi' : 'resultBox'">
        <!--좌 이미지-->
        <ul class="imgBox" v-if="!isFirstRecord">
            <li>
                <div class="imgW taC">
                    <img :src="record.firstSpine.spineImgUrl" alt="최초 분석 척추 엑스레이 이미지" @error="onErrorImage($event)">
                </div>
                <p class="date">{{ record.firstSpine.pictureDtStr }} <span style="display: inline-block;">({{ $t('page.result.text.init') }})</span></p>
            </li>
            <li>
                <div class="imgW taC">
                    <img :src="record.current.spineImgUrl" alt="현재 척추 엑스레이 이미지" @error="onErrorImage($event)">
                </div>
                <p class="date current">{{ record.current.pictureDtStr }} <span style="display: inline-block;">({{ $t('page.result.text.now') }})</span></p>
            </li>
        </ul>
        <div class="imgBox firstBox" v-else>
            <div class="imgW taC"><img :src="record.current.spineImgUrl" alt="현재 척추 엑스레이 이미지" @error="onErrorImage($event)"></div>
            <p class="date current">{{ record.current.pictureDtStr }} ({{ $t('page.result.text.now') }})</p>
        </div>
        <!--//좌 이미지-->
        <!--우 텍스트-->
        <ul class="txtList" v-if="!isFirstRecord">
            <li>
                <!--테이블방식(24.01.23)-->
                <div class="tblBg2">
                    <table class="cobbTbl">
                        <caption></caption>
                        <colgroup>
                            <col style="width:10px;">
                            <col style="width:40%;">
                            <col />
                            <col />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>{{ $t('common.analysis.result.spine.angle') }}</th>
                                <th>{{ $t('common.analysis.result.spine.apex') }}</th>
                                <th>{{ $t('common.analysis.result.spine.dir') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>1</th>
                                <td>{{ record.current.firstAngle }}˚</td>
                                <td>{{ record.current.firstApex }}</td>
                                <td>{{ translateDir(record.current.firstDirection) }}</td>
                            </tr>
                            <tr v-if="record.current.secondAngle">
                                <th>2</th>
                                <td>{{ record.current.secondAngle }}˚</td>
                                <td>{{ record.current.secondApex }}</td>
                                <td>{{ translateDir(record.current.secondDirection) }}</td>
                            </tr>
                            <tr v-if="record.current.thirdAngle">
                                <th>3</th>
                                <td>{{ record.current.thirdAngle }}˚</td>
                                <td>{{ record.current.thirdApex }}</td>
                                <td>{{ translateDir(record.current.thirdDirection) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </li>
            <li v-if="record.prev">
                <p class="tit">{{ $t('page.result.text.prevResult') }} <span class="gray stxt">({{ $t('common.analysis.pictureDt') }} : {{ record.prev.pictureDtStr }})</span></p>
                <ul class="resultInfo">
                    <li>
                        <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 1</p>
                        <p class="rightTxt">{{ record.prev.firstAngle }}˚ <span>({{ record.prev.firstApex }}, <span>{{ translateDir(record.prev.firstDirection) }}</span>)</span></p> 
                    </li>
                    <li v-if="record.prev.secondAngle">
                        <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 2</p>
                        <p class="rightTxt">{{ record.prev.secondAngle }}˚ <span>({{ record.prev.secondApex }}, <span>{{ translateDir(record.prev.secondDirection) }}</span>)</span></p> 
                    </li>
                    <li v-if="record.prev.thirdAngle">
                        <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 3</p>
                        <p class="rightTxt">{{ record.prev.thirdAngle }}˚ <span>({{ record.prev.thirdApex }}, <span>{{ translateDir(record.prev.thirdDirection) }}</span>)</span></p> 
                    </li>
                </ul>
            </li>
            <li>
                <p class="tit">{{ $t('page.result.text.initResult') }} <span class="gray stxt">({{ $t('common.analysis.pictureDt') }} : {{ record.firstSpine.pictureDtStr }})</span></p>
                <ul class="resultInfo">
                    <li>
                        <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 1</p>
                        <p class="rightTxt">{{ record.firstSpine.firstAngle }}˚ <span>({{ record.firstSpine.firstApex }}, <span>{{ translateDir(record.firstSpine.firstDirection) }}</span>)</span></p> 
                    </li>
                    <li v-if="record.firstSpine.secondAngle">
                        <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 2</p>
                        <p class="rightTxt">{{ record.firstSpine.secondAngle }}˚ <span>({{ record.firstSpine.secondApex }}, <span>{{ translateDir(record.firstSpine.secondDirection) }}</span>)</span></p> 
                    </li>
                    <li v-if="record.firstSpine.thirdAngle">
                        <p class="leftTxt"><span v-if="window.width > 1550">Cobb's </span>angle 3</p>
                        <p class="rightTxt">{{ record.firstSpine.thirdAngle }}˚ <span>({{ record.firstSpine.thirdApex }}, <span>{{ translateDir(record.firstSpine.thirdDirection) }}</span>)</span></p> 
                    </li>
                </ul>
            </li>
        </ul>
        <div :class="[`txtList`, isFirstRecord ? `firstTextList` : ``]" v-else>
            <div class="tblBg2">
                <table class="cobbTbl">
                    <caption></caption>
                    <colgroup>
                        <col style="width:10px;">
                        <col style="width:40%;">
                        <col />
                        <col />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>{{ $t('common.analysis.result.spine.angle') }}</th>
                            <th>{{ $t('common.analysis.result.spine.apex') }}</th>
                            <th>{{ $t('common.analysis.result.spine.dir') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>1</th>
                            <td>{{ record.current.firstAngle }}˚</td>
                            <td>{{ record.current.firstApex }}</td>
                            <td>{{ translateDir(record.current.firstDirection) }}</td>
                        </tr>
                        <tr v-if="record.current.secondAngle">
                            <th>2</th>
                            <td>{{ record.current.secondAngle }}˚</td>
                            <td>{{ record.current.secondApex }}</td>
                            <td>{{ translateDir(record.current.secondDirection) }}</td>
                        </tr>
                        <tr v-if="record.current.thirdAngle">
                            <th>3</th>
                            <td>{{ record.current.thirdAngle }}˚</td>
                            <td>{{ record.current.thirdApex }}</td>
                            <td>{{ translateDir(record.current.thirdDirection) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <span class="firstVisitW" v-if="isReport">
                    <p class="firstVisit" v-html="firstVisitMsg"></p>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    props: {
        record: {
            type: Object,
            default: () => ({}) 
        },
        isFirstRecord: {
            default: true
        },
        isReport: {
            default: false
        }
    },
    data() {
        return {
            key: 0,
        };
    },
    watch: {
    },
    created() {
        
    },
    mounted() {
    },
    destroyed() {},
    methods: {
    },
    computed: {
        firstVisitMsg() {
            return this.$t('page.report.text.first');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
#report .box > li .divi div.imgBox{display:flex; flex-direction:column; justify-content:center; align-items:center; gap:8px; width:200px;}
#report .box > li .divi div.imgBox .date.current{font-size:1.2rem; color:var(--color-point);}
#report .box > li .divi div.imgBox .imgW{width:100%; height:270px; background:#444; position: relative;}
#report .box > li .divi div.imgBox .imgW img{position: absolute; max-width: 100%; max-height: 100%; left: 50%; top: 50%; transform: translate(-50%, -50%);}
#report .box > li .divi .txtList{width:calc(100% - 216px);}
.firstVisitW{display: flex; flex-direction: column; gap: 4px; width: 100%;}
.firstVisit{padding: 16px; text-align: center; font-size: 1.4rem; line-height: 1.3em; word-break: keep-all}
</style>